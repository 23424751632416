:root {
  --black: #000000;
  --white: #ffffff;

  --unit: 20px;

  --fs-body: 8px;
  --lh-body: 16px;

  --fs-headline: 16px;
  --lh-headline: 22px;

  --fs-big: 32px;
  --lh-big: 32px;

  --pixel: calc((100vw - (var(--unit) * 11)) / 64);
}

@media screen and (max-width: 1350px) {
  :root {
    /*--pixel: calc((100vw - (var(--unit) * 9)) / 58);*/
    /* Grid Glitch Fix */
    --pixel: calc((100vw - (var(--unit) * 9)) / 57);
  }
}

@media screen and (max-width: 900px) {
  :root {
    --pixel: calc((100vw - (var(--unit) * 7)) / 32);
  }
}

@media screen and (max-width: 640px) {
  :root {
    --fs-big: 16px;
    --lh-big: 16px;
  }
}

@media screen and (max-width: 600px) {
  :root {
    --pixel: calc((100vw - (var(--unit) * 5)) / 16);
  }
}

/* -------------------------- */

iframe {
  display: none !important;
}

/* -------------------------- */

a {
  text-decoration: none;
}

/* -------------------------- */

h1,
h2,
h3 {
  position: relative;
  margin: 0;

  font-weight: normal;
}

h1 {
  font-size: var(--fs-big);
  line-height: var(--lh-big);
}

h2 {
  margin: 0 0 calc(var(--unit) * 2) 0;
  padding: 0 0 calc(var(--unit) * 1.3);
  border-bottom: 4px solid var(--black);

  font-size: var(--fs-headline);
  line-height: var(--lh-headline);
}

h3 {
  font-size: var(--fs-headline);
  line-height: var(--lh-headline);
}

/* -------------------------- */

body {
  margin: 0;
  padding: 0;

  font-family: 'Press Start 2P', cursive;
  font-size: var(--fs-body);
  line-height: var(--lh-body);
  color: var(--black);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* -------------------------- */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* -------------------------- */

header {
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: calc(var(--unit) * 3);
  background-color: var(--black);

  color: var(--white);
}

header .inner {
  padding: 0 var(--unit);
  height: inherit;
}

header a {
  color: var(--white);
}

@media (hover: hover) {
  header a:hover {
    color: blue;
  }
}

header .columns {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: inherit;
}

header .columns .column {
  width: 50%;
}

header .columns .column:nth-child(2) {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

header .columns .column:nth-child(2) a {
  margin-left: calc(var(--unit) * 1);
}

header button {
  margin-left: calc(var(--unit) * 1);
}

@media screen and (max-width: 600px) {
  header .columns .column:nth-child(2) a:last-child {
    display: none;
  }

  header .columns .column:first-child,
  header .columns .column:last-child {
    width: auto;
  }

  header .columns .column:last-child {
    margin-left: auto;
  }
}

/* -------------------------- */

.content {
  margin-top: calc(var(--unit) * 3);
  padding: calc(var(--unit) * 2);
}

@media screen and (min-width: 1468px) {
  .content {
    margin-right: auto;
    margin-left: auto;
    max-width: 1468px;
  }
}

/* -------------------------- */

button {
  -webkit-appearance: none;
  border-radius: 0;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  background: none;

  cursor: pointer;
  padding: calc(var(--unit) * 0.25) calc(var(--unit) * 0.5);
  background-color: var(--white);

  font-family: 'Press Start 2P', cursive;
  font-size: 8px;
  font-weight: normal !important;
  line-height: 16px;
  color: var(--black);
}

button.Mui-disabled {
  opacity: 0.25;
}

button.ButtonInvert {
  background-color: var(--black);

  color: var(--white);
}

button:hover {
  background-color: blue;

  color: var(--white);
}

button:focus {
  background-color: green;

  color: var(--white);
}

button:active {
  background-color: red;

  color: var(--white);
}

/* -------------------------- */

.LazyTokenGrid:nth-child(n + 2) {
  margin-top: calc(var(--unit) * 4);
}

.TokenGrid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /*justify-content: space-between;*/
  width: 100%;
}

@media screen and (min-width: 1351px) {
  .TokenGrid .Token {
    margin-right: calc(var(--unit) * 1);
    width: calc(1 / 8 * 100% - (1 - 1 / 8) * (var(--unit) * 1));
  }

  .TokenGrid .Token:nth-child(8n + 8) {
    margin-right: 0;
  }

  .TokenGrid .Token:nth-child(n + 9) {
    margin-top: calc(var(--unit) * 2);
  }
}

@media screen and (max-width: 1350px) {
  .TokenGrid .Token {
    margin-right: calc(var(--unit) * 1);
    width: calc(1 / 7 * 100% - (1 - 1 / 7) * (var(--unit) * 1));
  }

  .TokenGrid .Token:nth-child(7n + 7) {
    margin-right: 0;
  }

  .TokenGrid .Token:nth-child(n + 8) {
    margin-top: calc(var(--unit) * 2);
  }
}

@media screen and (max-width: 900px) {
  .TokenGrid .Token {
    margin-right: calc(var(--unit) * 1);
    width: calc(1 / 4 * 100% - (1 - 1 / 4) * (var(--unit) * 1));
  }

  .TokenGrid .Token {
    margin-right: calc(var(--unit) * 1) !important;
  }

  .TokenGrid .Token:nth-child(4n + 4) {
    margin-right: 0 !important;
  }

  .TokenGrid .Token:nth-child(n + 5) {
    margin-top: calc(var(--unit) * 2);
  }
}

@media screen and (max-width: 600px) {
  .TokenGrid .Token {
    margin-right: calc(var(--unit) * 1);
    width: calc(1 / 2 * 100% - (1 - 1 / 2) * (var(--unit) * 1));
  }

  .TokenGrid .Token {
    margin-right: calc(var(--unit) * 1) !important;
  }

  .TokenGrid .Token:nth-child(2n + 2) {
    margin-right: 0 !important;
  }

  .TokenGrid .Token:nth-child(n + 3) {
    margin-top: calc(var(--unit) * 2);
  }
}

.TokenGrid .Token a {
  display: block;

  color: var(--black);
}

.TokenGrid .Token .Token__Metadata {
  padding-top: calc(var(--unit) * 1);
}

.TokenGrid .Token .Token__Metadata .Token__Cols {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.Token__Editions,
.Token__Price {
  width: 50%;
}

.Token__Price {
  text-align: right;
}

.Token__Creator {
  overflow: hidden;
  width: calc(var(--pixel) * 6);
  opacity: 0.4;

  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (min-width: 1468px) {
  .Token__Creator {
    width: 166px;
  }
}

.Button {
  border-radius: 0 !important;
  border: 0 !important;
  outline: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  background: none !important;

  cursor: pointer;
  margin-top: calc(var(--unit) * 2) !important;
  padding: calc(var(--unit) * 0.25) calc(var(--unit) * 0.5) !important;
  width: 100% !important;
  background-color: var(--black) !important;

  font-family: 'Press Start 2P', cursive !important;
  font-size: 8px !important;
  font-weight: normal !important;
  line-height: 16px !important;
  text-transform: none !important;
  color: var(--white) !important;
  white-space: nowrap;
}

.Button--autoWidth {
  width: auto !important;
}

.Button:hover {
  background-color: blue !important;

  color: var(--white);
}

.Button:focus {
  background-color: green !important;

  color: var(--white);
}

.Button:active {
  background-color: red !important;

  color: var(--white);
}

/* -------------------------- */

.Preview {
  pointer-events: none;
  overflow: hidden;
  width: 100%;
}

@media (hover: hover) {
  .Token a:hover .Preview {
    position: relative;
    transform: translate3d(-8px, -8px, 0);
    box-shadow: 8px 8px 0 rgba(0, 0, 0, 1);
  }
}

.Preview__Canvas {
  image-rendering: pixelated;
  display: block;
  margin-bottom: -1px;
  width: calc(100% + 1px);
  height: auto;
}

.Preview--large {
  width: calc((100vw * 0.33) - (var(--unit) * 3));
}

@media screen and (max-width: 900px) {
  .Preview--large {
    width: calc(100vw - (var(--unit) * 4));
  }
}

@media screen and (min-width: 1468px) {
  .Preview--large {
    width: 423px;
  }
}

.TokenDetail .Token__Cols {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
}

@media screen and (max-width: 1150px) {
  .TokenDetail .Token__Cols {
    align-items: flex-start;
  }
}

@media screen and (max-width: 900px) {
  .TokenDetail .Token__Cols {
    display: block;
  }
}

.TokenDetail__Meta h3 {
  margin-bottom: calc(var(--unit) * 1);
}

.TokenDetail__Meta {
  flex-grow: 2;
  position: relative;
  margin-left: calc(var(--unit) * 4);
}

@media (hover: hover) {
  .TokenDetail__Meta a:hover {
    color: blue;
  }
}

@media screen and (max-width: 900px) {
  .TokenDetail__Meta {
    margin-top: calc(var(--unit) * 2);
    margin-left: 0;
  }
}

.Token__Subcols {
  display: flex;
  flex-wrap: nowrap;
  margin-top: calc(var(--unit) * 1);
  width: 100%;
}

.Token__Subcols .TokenDetail__MetaInfo:nth-child(n + 2) {
  margin-left: calc(var(--unit) * 2);
}

.TokenDetail__8bidou a {
  display: inline-block;
  margin-top: calc(var(--unit) * 1);
  padding: calc(var(--unit) * 0.25) calc(var(--unit) * 0.5);
  border: 2px solid var(--black);

  font-family: 'Press Start 2P', cursive;
  font-size: 8px;
  font-weight: normal !important;
  line-height: 16px;
  color: var(--black);
}

@media (hover: hover) {
  .TokenDetail__8bidou a:hover {
    background-color: blue;
    border-color: blue;

    color: var(--white);
  }
}

.TokenDetail__MetaInfo {
  margin-top: calc(var(--unit) * 0.75);
}

.TokenDetail__MetaInfo a {
  color: var(--black);
}

.TokenWrapperColumns {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
}

.TokenWrapper {
  margin-top: calc(var(--unit) * 4);
  width: calc(1 / 2 * 100% - (1 - 1 / 2) * (var(--unit) * 4));
}

@media screen and (max-width: 1000px) {
  .TokenWrapperColumns {
    display: block;
  }

  .TokenWrapper {
    width: 100%;
  }
}

.LoadingLayer {
  z-index: 2000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  background-color: var(--black);

  color: var(--white);
}
.ListingsAndHoldings ul {
  margin: 0;
  padding: 0;
}

.ListingsAndHoldings ul li {
  list-style: none;
}

.Sales ul {
  margin: 0;
  padding: 0;
}

.Sales ul li {
  list-style: none;
}

.Price .Taco {
  display: inline-block;
  margin-left: 3px;
  transform: translate3d(0, -1px, 0);
}

.SalesTable,
.HoldingsTable,
.ListingsTable {
  width: 100%;
}

.HoldingsTable {
  margin-top: calc(var(--unit) * 1);
}

.HoldingsTable:first-child {
  margin-top: 0;
}

.SalesTable a,
.HoldingsTable a,
.ListingsTable a {
  color: var(--black);
}

.SalesTable td.SalesTable__Price,
.SalesTable td.SalesTable__Time {
  text-align: right;
}

.ListingsTable tr td:nth-child(2) {
  text-align: right;
}

.ListingsTable button {
  margin-top: 0 !important;
}

@media screen and (max-width: 500px) {
  .SalesTable tr td:nth-child(2) a:first-child,
  .SalesTable tr td:nth-child(2) span {
    display: none;
  }
}

.Intro {
  margin-top: calc(var(--unit) * 2);

  text-align: center;
}

.Intro p {
  margin: calc(var(--unit) * 2) auto 0 auto;
  width: 560px;
}

@media screen and (max-width: 640px) {
  .Intro h1 span {
    /*display: block;
    margin: 10px 0;*/
  }

  .Intro p {
    width: 100%;
  }
}

.Intro p a {
  padding-bottom: 2px;
  border-bottom: 1px solid var(--black);

  color: var(--black);
}

@media (hover: hover) {
  .Intro p a:hover {
    border-color: blue;

    color: blue;
  }
}

.Intro .ButtonGroup {
  margin-top: calc(var(--unit) * 2);
}

.Intro .ButtonGroup button {
  margin: 0 calc(var(--unit) * 1);
}

.UserListings h2 .UserListings__Toggle {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate3d(0, 10px, 0);
}

@media screen and (max-width: 450px) {
  .UserListings h2 .UserListings__Toggle {
    position: relative;
    top: auto;
    right: auto;
    margin-top: calc(var(--unit) * 0.5);
    transform: translate3d(0, 0, 0);
  }

  .UserListings h2 .UserListings__Toggle > label {
    margin-left: 0;
  }
}

.UserListings:nth-child(n + 2) {
  margin-top: calc(var(--unit) * 4);
}

.MuiFormControlLabel-label {
  font-family: 'Press Start 2P', cursive !important;
  font-size: var(--fs-body) !important;
  line-height: var(--lh-body) !important;
  color: var(--black);
}

.UserListings .Button {
  margin-top: 0 !important;
}

.UserListings table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.UserListings table thead {
  text-align: left;
}

.UserListings table thead tr th,
.UserListings table tbody tr td {
  padding: 0;
  width: calc(100% / 6);
}

.UserListings table thead tr th {
  padding-bottom: calc(var(--unit) * 1);

  font-weight: normal;
}

.UserListings table tbody tr:nth-child(odd) td {
  background-color: #f7f7f7;
}

.UserListings table thead tr th:nth-child(1),
.UserListings table tbody tr td:nth-child(1) {
  padding-right: calc(var(--unit) * 1);
  width: 4%;
}

.UserListings table thead tr th:last-child,
.UserListings table tbody tr td:last-child {
  padding-right: calc(var(--unit) * 0.5);

  text-align: right;
}

.UserListings table tbody tr td:nth-child(1) a {
  display: block;
  margin: 3px;
}

.UserListings table thead tr th:nth-child(2),
.UserListings table tbody tr td:nth-child(2) {
  width: 16%;
}

@media screen and (max-width: 900px) {
  .UserListings table thead tr th:nth-child(5),
  .UserListings table tbody tr td:nth-child(5) {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .UserListings table thead tr th:nth-child(2),
  .UserListings table tbody tr td:nth-child(2) {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .UserListings table thead tr th:nth-child(3),
  .UserListings table tbody tr td:nth-child(3) {
    display: none;
  }
}

.UserDetail__Meta {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.UserDetail__MetaInfo {
  width: calc(100% / 5);
}

@media screen and (max-width: 900px) {
  .UserDetail__MetaInfo {
    width: calc(100% / 2);
  }

  .UserDetail__MetaInfo:nth-child(n + 3) {
    margin-top: calc(var(--unit) * 1);
  }
}

a {
  color: var(--black);
}

.TwitterLink {
  margin-left: calc(var(--unit) * 0.5);
}

.User {
  position: relative;
  padding-right: 20px;
}

@media (hover: hover) {
  .User:hover .User__Icon em {
    transform: translate3d(1px, -1px, 0) scaleX(-1);
  }
}

.User__Icon {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  width: 15px;
  height: 15px;
  transform: translate3d(0, -4px, 0);
  background-color: var(--black);
  border-radius: 15px;

  font-size: 8px;
  line-height: 15px;
  text-align: center;
}

.User__Icon em {
  display: block;
  transform: translate3d(-1px, -1px, 0);

  font-style: normal;
}

.CodeError {
  overflow-x: auto;
  width: 100%;
}
